var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"completa mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Agenda completa ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('h3',{staticClass:"text-center"},[_vm._v(" Estados de la cita ")]),_c('div',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-checkbox-blank-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Disponible")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-circle")])],1)]}}])},[_c('span',[_vm._v("Cita pendiente")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-circle")])],1)]}}])},[_c('span',[_vm._v("Cita confirmada")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal darken-4"}},[_vm._v("mdi-circle")])],1)]}}])},[_c('span',[_vm._v("Cita recordada")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-circle")])],1)]}}])},[_c('span',[_vm._v("Cita en proceso")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green accent-3"}},[_vm._v("mdi-circle")])],1)]}}])},[_c('span',[_vm._v("Cita pagada")])]),_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-circle")])],1)]}}])},[_c('span',[_vm._v("Hora bloqueada")])])],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn-toggle',{attrs:{"tile":"","group":"","color":"primary"},model:{value:(_vm.type_view),callback:function ($$v) {_vm.type_view=$$v},expression:"type_view"}},[_c('v-btn',{attrs:{"value":1},on:{"click":function($event){_vm.type_view = 1}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-timer-sand")]),_vm._v(" Agenda ")],1),_c('v-btn',{attrs:{"value":2},on:{"click":function($event){_vm.type_view = 2}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(" Calendario ")],1)],1)],1),(_vm.type_view == 1)?_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('agenda-completa',{attrs:{"horarios":_vm.horarios,"profesionales":_vm.profesionales,"date":_vm.date}})],1):_vm._e(),(_vm.type_view == 2)?_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('calendario',{attrs:{"profesionales":_vm.profesionales,"config":_vm.config}})],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }