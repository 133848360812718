<template>
  <div class="completa mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Agenda completa
          </v-card-title>
          <v-card-text>
            <v-row>
              <!-- Calendario -->
              <v-col cols="12" md="4" sm="6">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="date" outlined rounded dense label="Fecha" append-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker scrollable v-model="date" locale="es-co" color="primary" first-day-of-week="1"
                    @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <!-- Estados -->
              <v-col cols="12" md="6" sm="6">
                <v-row no-gutters>
                  <v-col cols="8">
                    <h3 class="text-center">
                      Estados de la cita
                    </h3>
                    <div align="center">
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="primary">mdi-checkbox-blank-circle-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Disponible</span>
                      </v-tooltip>
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="purple">mdi-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Cita pendiente</span>
                      </v-tooltip>
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="success">mdi-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Cita confirmada</span>
                      </v-tooltip>
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="teal darken-4">mdi-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Cita recordada</span>
                      </v-tooltip>
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="primary">mdi-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Cita en proceso</span>
                      </v-tooltip>
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="green accent-3">mdi-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Cita pagada</span>
                      </v-tooltip>
                      <v-tooltip top color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon color="grey">mdi-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Hora bloqueada</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-btn-toggle tile group color="primary" v-model="type_view">
                  <v-btn @click="type_view = 1" :value="1">
                    <v-icon left>mdi-timer-sand</v-icon>
                    Agenda
                  </v-btn>
                  <v-btn @click="type_view = 2" :value="2">
                    <v-icon left>mdi-calendar</v-icon>
                    Calendario
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="type_view == 1">
                <agenda-completa :horarios="horarios" :profesionales="profesionales" :date="date" />
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="type_view == 2">
                <calendario :profesionales="profesionales" :config="config" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
import agendaCompleta from "../../components/AgendaCompleta.vue";
import calendario from "../../components/Calendario.vue";
moment.locale("es");
export default {
  name: "completa",
  components: {
    calendario,
    agendaCompleta,
  },
  data: () => ({
    type_view: 1,
    tab: null,
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    profesionales: [],
    horarios: {
      inicio_d: "",
      fin_d: "",
      inicio_s: "",
      fin_s: "",
      inicio_lv: "",
      fin_lv: "",
    },
  }),
  methods: {
    loadData() {
      const body = {
        route: "/primary_data",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.$store.dispatch("setDataCenter", {
              plan: response.data.data.plan,
              agenda: response.data.data.agenda,
              route_img: response.data.data.route,
              image: response.data.data.image,
            });
          }
        })
        .catch((error) => {
          this.$store.dispatch("logout", this.$store.state.access_token);
        });
    },
    loadHorarios() {
      const body = {
        route: "/sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.horarios = JSON.parse(response.data.data.horarios);
        }
      });
    },
    loadProfesionales() {
      const body = {
        route: "/profesionales_disponibles",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data;
        }
      });
    },
  },
  created() {
    this.loadData();
    this.loadHorarios();
    this.loadProfesionales();
  },
  mounted() {
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    config() {
      return {
        date: this.date,
        start: this.horarios.inicio_lv,
        end: this.horarios.fin_lv,
      };
    },
  },
};
</script>
