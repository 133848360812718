<template>
  <div class="ag-completa">
    <v-col sm="12" md="12" cols="12" v-if="apertura.inicio == null">
      <v-alert type="warning">
        El centro no cuenta con agenda para este dia
      </v-alert>
    </v-col>
    <v-col sm="12" md="12" cols="12" style="display: flex;
        overflow: hidden;
        overflow-x: scroll;" v-else>
      <!-- contenedor agendas -->
      <div class="container-agenda">
        <!-- contenedor profesionales -->
        <div class="header-prof scroll-p" id="scroll-p" @mousewheel="mouseEvent" @scroll="scrollEvent">
          <v-col v-if="headerAgenda.length == 0">
            <div class="text-center">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-col>
          <v-col v-for="(profesional, index) in headerAgenda" :key="index" v-else>
            <div align="center">
              <div style="width: 100px;">
                <v-avatar>
                  <img :src="
                    $store.state.centro.route +
                    'profesionales/' +
                    profesional.foto
                  " />
                </v-avatar>
                <br />
                <strong>
                  {{ profesional.apodo }}
                </strong>
              </div>
            </div>
          </v-col>
        </div>
        <!-- contenedor horas por profesional -->
        <div class="horas scroll-h" id="scroll-h">
          <v-col v-if="agendaFinal.length == 0">
            <div class="text-center">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-col>
          <v-col v-for="(item, index) in agendaFinal" :key="index" v-else>
            <div align="center" class="mt-2" v-for="(hora, i) in item" :key="i">
              <v-btn width="100px" outlined small color="primary" v-if="hora.estado == 0">
                {{ hora.hora }}
              </v-btn>
              <v-tooltip top max-width="250" v-if="hora.estado == 1 || hora.estado == 2 || hora.estado == 3">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn width="100px" small dark color="purple" v-on="on" v-bind="attrs" v-if="hora.estado == 1"
                    @click="opendialog(hora)">
                    {{ hora.hora }}
                  </v-btn>
                  <v-btn width="100px" small dark color="success" v-on="on" v-bind="attrs" v-if="hora.estado == 2"
                    @click="opendialog(hora)">
                    {{ hora.hora }}
                  </v-btn>
                  <v-btn width="100px" small dark color="teal darken-4" v-on="on" v-bind="attrs" v-if="hora.estado == 3"
                    @click="opendialog(hora)">
                    {{ hora.hora }}
                  </v-btn>
                </template>
                <span>
                  {{ `${hora.cliente} - [${hora.servicio}]` }}
                </span>
              </v-tooltip>
              <v-btn width="100px" small dark color="primary" v-if="hora.estado == 4">
                {{ hora.hora }}
              </v-btn>
              <v-btn width="100px" small dark color="grey" v-if="hora.estado == 5">
                {{ hora.hora }}
              </v-btn>
              <v-btn width="100px" small dark color="green accent-3" v-if="hora.estado == 6">
                {{ hora.hora }}
              </v-btn>
            </div>
          </v-col>
        </div>
        <v-dialog v-model="dialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline">
              Notas de la cita
            </v-card-title>
            <v-card-text>
              <strong>
                {{ cita.cliente }} - [{{ cita.servicio }} - {{ cita.hora }}]
              </strong>
              <v-textarea v-model="cita.notas" auto-grow rows="5"></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey" text @click="dialog = false">
                cerrar
              </v-btn>
              <v-btn :dark="!loading" :color="!loading ? 'black' : 'gray'" :loading="loading" :disabled="loading"
                @click="acciones()">
                Actualizar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-col>
  </div>
</template>
<script>
import * as moment from "moment";
export default {
  name: "agendaCompleta",
  props: ["horarios", "profesionales", "date"],
  data: () => ({
    horas: {
      agenda: [],
      bloqueos: [],
    },
    loading: false,
    dialog: false,
    cita: {},
  }),
  methods: {
    loadHoras() {
      const body = {
        route: "/tiempo_agendado",
        params: {
          date: this.date,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.horas = response.data.data;
        }
      });
    },
    // Calcular tiemp de duracion del servicio por agendar
    iteracion(inicio, fin) {
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }
      let start = moment(inicio, "HH:mm");
      let end = moment(fin, "HH:mm").add(
        -this.$store.state.centro.agenda,
        "minutes"
      );
      let rangeArray = calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return hora.format("HH:mm");
      });
      return rangeArray;
    },
    opendialog(cita) {
      this.cita = cita;
      this.dialog = true;
    },
    acciones() {
      this.loading = true;
      const body = {
        route: "/acciones_agenda",
        data: {
          cita: this.cita.cita_id,
          type: "obs",
          value: this.cita.notas,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            this.loadHoras();
            this.dialog = false;
          }
        })
        .catch((error) => { })
        .finally(() => (this.loading = false));
    },
    mouseEvent(event) {
      let targetH = document.getElementsByClassName("scroll-h")[0];
      let HtoLeft = event.deltaY < 0 && targetH.scrollLeft > 0;
      let HtoRight =
        event.deltaY > 0 &&
        targetH.scrollLeft < targetH.scrollWidth - targetH.clientWidth;
      if (HtoLeft || HtoRight) {
        event.preventDefault();
        targetH.scrollLeft += event.deltaY;
      }

      let targetP = document.getElementsByClassName("scroll-p")[0];
      let PtoLeft = event.deltaY < 0 && targetP.scrollLeft > 0;
      let PtoRight =
        event.deltaY > 0 &&
        targetP.scrollLeft < targetP.scrollWidth - targetP.clientWidth;
      if (PtoLeft || PtoRight) {
        event.preventDefault();
        targetP.scrollLeft += event.deltaY;
      }
    },
    scrollEvent(event) {
      let timeStamp = event.timeStamp;
      let id = event.target.id;
      let target;
      if (id == "scroll-h") {
        target = document.getElementsByClassName("scroll-p")[0];
      } else {
        target = document.getElementsByClassName("scroll-h")[0];
      }
      event.preventDefault();
      target.scrollLeft = event.target.scrollLeft;
      target.timeStamp = timeStamp;
    },
  },
  created() {
    this.loadHoras();
  },
  computed: {
    apertura() {
      let horarios = { inicio: null, fin: null };
      if (moment(this.date).isoWeekday() == 7) {
        horarios.inicio = this.horarios.inicio_d;
        horarios.fin = this.horarios.fin_d;
      }

      if (moment(this.date).isoWeekday() == 6) {
        horarios.inicio = this.horarios.inicio_s;
        horarios.fin = this.horarios.fin_s;
      }

      if (moment(this.date).isoWeekday() <= 5) {
        horarios.inicio = this.horarios.inicio_lv;
        horarios.fin = this.horarios.fin_lv;
      }
      return horarios;
    },
    // Generar agenda del profesional
    agenda() {
      function calculateRange(start, end, minutes) {
        let range = [];
        for (
          let hour = moment(start);
          hour.isBefore(end);
          hour.add(minutes, "minutes")
        ) {
          range.push(moment(hour));
        }
        range.push(moment(end));
        return range;
      }
      let start = moment(this.apertura.inicio, "HH:mm");
      let end = moment(this.apertura.fin, "HH:mm");
      let rangeArray = calculateRange(
        start,
        end,
        parseInt(this.$store.state.centro.agenda)
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
          estado: 0,
        };
      });

      return rangeArray;
    },
    profesionalesList() {
      const profesionales = this.profesionales.map((prof) => {
        const agenda = this.agenda.map((ag) => {
          return {
            hora: ag.hora,
            estado: 0,
            p_id: prof.id,
            date: this.date,
            cliente: "",
            servicio: "",
            notas: "",
            cita_id: "",
          };
        });
        return {
          profesional: prof.nombre,
          id: prof.id,
          foto: prof.foto,
          apodo: prof.apodo,
          agenda: agenda,
        };
      });
      return profesionales;
    },
    headerAgenda() {
      return this.profesionalesList.map((prof) => {
        return {
          profesional: prof.nombre,
          id: prof.id,
          foto: prof.foto,
          apodo: prof.apodo,
        };
      });
    },
    agendaFinal() {
      const agenda = this.profesionalesList.map((prof) => {
        this.horas.agenda
          .filter((hora) => {
            return hora.profesional_id == prof.id;
          })
          .forEach((hora) => {
            const inicio = moment(hora.inicio, "HH:mm:ss").format("HH:mm");
            const fin = moment(hora.fin, "HH:mm:ss").format("HH:mm");
            this.iteracion(inicio, fin).forEach((hr) => {
              prof.agenda.forEach((ag) => {
                if (
                  moment(hr, "HH:mm").format("h:mm A") === ag.hora &&
                  hora.fecha == ag.date
                ) {
                  ag.cliente = hora.cliente;
                  ag.servicio = hora.servicio;
                  ag.cita_id = hora.id;
                  ag.notas = hora.notas;
                  if (hora.estado == 1) {
                    // Pendiente
                    ag.estado = 1;
                  } else if (hora.estado == 2 && hora.recordatorio_state == 0) {
                    // Confirmada
                    ag.estado = 2;
                  } else if (hora.estado == 2 && hora.recordatorio_state == 1) {
                    // Recordada
                    ag.estado = 3;
                  } else if (hora.estado == 3) {
                    // Procesada
                    ag.estado = 4;
                  }else if (hora.estado == 4) {
                    // Pagada
                    ag.estado = 6;
                  }
                }
              });
            });
          });

        this.horas.bloqueos
          .filter((hora) => {
            return hora.profesional_id == prof.id;
          })
          .forEach((hora) => {
            prof.agenda.forEach((ag) => {
              if (
                moment(hora.hora, "HH:mm:ss").format("h:mm A") === ag.hora &&
                hora.fecha == ag.date
              ) {
                // Hora bloqueada
                ag.estado = 5;
              }
            });
          });
        return prof.agenda;
      });
      return agenda;
    },
  },
  watch: {
    date(val) {
      this.horas = {
        agenda: [],
        bloqueos: [],
      };
      this.loadHoras();
      return this.date;
    },
  },
};
</script>
