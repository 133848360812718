<template>
  <div class="calendario">
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
        v-for="(item, index) in citas"
        :key="index"
      >
        <v-list-item>
          <v-list-item-avatar>
            <img
              :src="$store.state.centro.route + 'profesionales/' + item.foto"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <strong>
              {{ item.profesional }}
            </strong>
          </v-list-item-content>
        </v-list-item>
        <v-sheet height="500">
          <v-calendar
            ref="calendar"
            :now="config.date"
            :start="config.date"
            :weekdays="weekday"
            :events="item.citas"
            color="primary"
            type="week"
            min-weeks="1"
            :interval-minutes="$store.state.centro.agenda"
            :weekday-format="myDayFormat"
            :first-time="config.start"
            :interval-count="intervalCount"
            :short-intervals="false"
            interval-height="45"
            :event-color="getEventColor"
          >
            <template v-slot:event="{ event }">
              <v-tooltip top max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <div align="center" v-on="on" v-bind="attrs">
                    {{ getHora(event.start) }}
                    <br />
                    {{ getHora(event.end) }}
                  </div>
                </template>
                <span>
                  {{ event.name }}
                </span>
              </v-tooltip>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "calendario",
  props: ["profesionales", "config"],
  data: () => ({
    agenda: [],
    weekday: [1, 2, 3, 4, 5, 6, 0],
  }),
  methods: {
    loadAgenda() {
      const body = {
        route: "/calendario_completa",
        params: {
          start: this.weekStart,
          end: this.weekEnd,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.agenda = response.data.data;
        }
      });
    },
    myDayFormat(day) {
      return moment(day.date).format("ddd");
    },
    getEventColor(cita) {
      return cita.color;
    },
    getHora(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("h:mm A");
    },
  },
  created() {
    this.loadAgenda();
  },
  computed: {
    weekStart() {
      return moment(this.config.date)
        .startOf("week")
        .format("YYYY-MM-DD");
    },
    weekEnd() {
      return moment(this.config.date)
        .endOf("week")
        .format("YYYY-MM-DD");
    },
    intervalCount() {
      const inicio = moment(this.config.start, "HH:mm");
      const fin = moment(this.config.end, "HH:mm");
      return Math.round(
        moment.duration(fin.diff(inicio)).asHours() *
          (60 / this.$store.state.centro.agenda)
      );
    },
    citas() {
      const color = (cita) => {
        let valor;
        if (cita.estado == 1) {
          valor = "purple";
        } else if (cita.estado == 2 && cita.recordatorio_state == 0) {
          valor = "success";
        } else if (cita.estado == 2 && cita.recordatorio_state == 1) {
          valor = "teal darken-4";
        } else if (cita.estado == 3) {
          valor = "primary";
        }  else if (cita.estado == 4) {
          valor = "green accent-3";
        } else if (cita.estado == 5) {
          valor = "error";
        }
        return valor;
      };
      const profesionales = this.profesionales.map((profesional) => {
        return {
          profesional: profesional.nombre,
          foto: profesional.foto,
          citas: this.agenda
            .filter((cita) => {
              return cita.profesional_id === profesional.id;
            })
            .map((cita) => {
              return {
                name: `${cita.cliente} - [ ${cita.servicio} ]`,
                color: color(cita),
                start: `${cita.fecha} ${moment(cita.inicio, "HH:mm:ss").format(
                  "HH:mm"
                )}`,
                end: `${cita.fecha} ${moment(cita.fin, "HH:mm:ss").format(
                  "HH:mm"
                )}`,
              };
            }),
        };
      });

      return profesionales;
    },
  },
  watch: {
    config(val, old) {
      const date = val.date;
      const date_old = old.date;
      if (moment(date).week() != moment(date_old).week()) {
        this.loadAgenda();
      }
      return this.config;
    },
  },
};
</script>
